// init
import axios from "axios";
import services from '../data.services/data-services-main.json'

const base = (services.data.find(x => x.code == 'offers')).link

// -----------------

export const AssetsOffersCreate = async (item) => {
  
  const basx = base + '/assets/offers/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetsOffersList = async (item) => {
  
    const basx = base + '/assets/offer/list/individual';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const AssetsOffersDetails = async (item) => {
  
    const basx = base + '/assets/offer/details/individual';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const AssetsOffersStatusSet = async (item) => {
  
    const basx = base + '/assets/individual/offers/status/set';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const AssetsOffersListResale = async (item) => {
  
    const basx = base + '/assets/offers/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const AssetsOffersResaleDetails = async (item) => {
  
    const basx = base + '/assets/offer/details';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { NAVSChangeList } from '../../services/srvc-navs-realm';

const NavLineChart = (props) => {
  const chartRef = useRef(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [date, setDate] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await NAVSChangeList({
          data: {
            unit: props.unit
          },
        });
        if (res && res.data && res.data.list) {
          const items = res.data.list;
          setCurrentItems(items);
          setDate(items.map(item => new Date(Number(item.crts.$numberLong)).toLocaleDateString()));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [props.unit]);

  useEffect(() => {

    const ctx = chartRef.current.getContext("2d");

    const yValues = currentItems.length > 1 ? 
    currentItems.map(item => parseFloat(item.rate.nmbr) / 1000000) : 
    currentItems.length == 1 ? [(currentItems[0] ?.rate?.nmbr/1000000),"0"] : [props.rate, "0"];
    yValues.reverse();

    const data = {
      labels: date.length>1?date.reverse():  date.length == 1 ? ["0",date[0]] :["0","Listing rate"],
      datasets: [{
        label: "Rate",
        data: yValues,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: {
          target: 'origin',
          above: 'rgba(75, 192, 192, 0.1)', // High opacity
          below: 'rgba(75, 192, 192, 0)' // Low opacity
        },
        tension: 0.4 // Adjust the tension for a smoother curve
      }]
    };


    const config = {
      type: "line",
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            }
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            }
          }
        }
      }
    };

    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
  }, [chartRef, currentItems, date]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default NavLineChart;

